<i18n>
  {
    "es": {
      "RESUME": "Resumen de gastos de envío",
      "AVAILABILITY": "Envío disponible para territorio español, excepto Ceuta y Melilla.",
      "FREE_SHIPPING": "Envío gratis a partir de 25€, de lo contrario +3,99€.",
      "SHIPPING_DAYS": "Entrega en 1 - 3 días hábiles.",
      "RETURNS": "Periodo de devolución de 30 días naturales."
    },
    "en": {
      "RESUME": "Shipping Costs Summary",
      "AVAILABILITY": "Shipping available for Spanish territory except Ceuta and Melilla.",
      "FREE_SHIPPING": "Free shipping for orders over 25€, otherwise 3.99€.",
      "SHIPPING_DAYS": "Delivery in 1 - 3 business days.",
      "RETURNS": "30-day return period."
    }
  }
  </i18n>

<template>
  <ModalCourtainShippings :visibility="visibility" @close="cancel">
    <template v-slot:title>
      <div class="flex justify-between items-end w-full bg-white">
        <h2
          class="mx-auto md:mx-0 md:px-2 flex text-lg md:text-xl tracking-wider font-bold text-sanremo-black text-center"
        >
          {{ t('RESUME') }}
        </h2>
        <button v-if="!isMobileOrTablet" type="button" @click="cancel">
          <img src="@/assets/svg/icons/icons-close.svg" class="h-7 w-7" />
        </button>
      </div>
      <div>
        <div
          v-if="isMobileOrTablet"
          class="h-px w-full pl-96 -ml-2 bg-neutral-light-grey-1 -mt-2"
        ></div>
      </div>
    </template>
    <template v-slot:content>
      <div class="mt-2 w-full">
        <p
          class="text-sm text-sanremo-black text-start -mt-3 leading-5 font-semibold tracking-widest"
        >
          {{ t('AVAILABILITY') }}
        </p>
        <div
          class="mt-4 flex flex-col gap-y-2 font-thin text-sanremo-black text-sm leading-5 tracking-wide"
        >
          <div class="flex gap-x-2 items-center">
            <img
              src="@/assets/svg/icons/icons-squared-check.svg"
              class="h-4 w-4"
            />
            <p>{{ t('FREE_SHIPPING') }}</p>
          </div>
          <div class="flex gap-x-2 items-center">
            <img
              src="@/assets/svg/icons/icons-squared-check.svg"
              class="h-4 w-4"
            />
            <p>{{ t('SHIPPING_DAYS') }}</p>
          </div>
          <div class="flex gap-x-2 items-center">
            <img
              src="@/assets/svg/icons/icons-squared-check.svg"
              class="h-4 w-4"
            />
            <p>{{ t('RETURNS') }}</p>
          </div>
        </div>
      </div>
    </template>
  </ModalCourtainShippings>
</template>

<script setup>
const props = defineProps({
  visibility: Boolean,
})

const { t } = useI18n({
  useScope: 'local',
})

const { isMobileOrTablet } = useDevice()

const emit = defineEmits(['close', 'delete'])

const cancel = () => {
  emit('close')
}
</script>
